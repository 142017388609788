import { caseApi } from '@/app/src/entities/case'
import { INextLink } from '@/app/src/shared/model'

const subnav = caseApi
  .getCategoriesForIndustries()
  .map(({ title, slug, id, iconName, isComingSoon = false, description }) => ({
    id,
    title,
    description,
    href: (slug === 'foodtech' ? `/${slug}` : `/industries/${slug}`) as INextLink,
    iconName,
    isComingSoon,
  }))

export const nav = [
  {
    id: 1,
    title: 'About us',
    titleAlternative: 'About us',
    href: '/about' as INextLink,
    subnav: [
      {
        id: 105,
        title: 'Our Team',
        href: `/about#our-team` as INextLink,
        iconName: 'sprite/our-team',
        isComingSoon: false,
        description: 'Get closer with our team',
      },
      {
        id: 110,
        title: 'Honor Board',
        href: `/honor-board` as INextLink,
        iconName: 'sprite/honor-board',
        isComingSoon: false,
        description: 'Take a look at all our awards',
      },
      {
        id: 115,
        title: 'How we work',
        href: `/process` as INextLink,
        iconName: 'sprite/how-we-work',
        isComingSoon: false,
        description: 'Go deep into our approach',
      },
      /*{
        id: 120,
        title: 'Our Products',
        href: `#` as INextLink,
        iconName: 'sprite/our-products',
        isComingSoon: true,
        description: null, //Explore our in-house experiments results
      },*/

      {
        id: 125,
        title: 'Partner with Us',
        href: `/partners` as INextLink,
        iconName: 'sprite/partner-with-us',
        isComingSoon: false,
        description: 'Choose collaboration format',
      },
    ],
  },
  {
    id: 5,
    title: 'Services',
    titleAlternative: 'Services',
    href: '/services' as INextLink,
    hasSlot: true, //TODO: когда добавят слот, тогда нужно будет заменить hasSlot на hasЧто-тоТам и заменить hasSlot в Navigation и NavigationMobile
    subnav: [
      {
        id: 505,
        title: 'Web Development',
        href: `/services/web-development` as INextLink,
        iconName: 'sprite/web-development',
        isComingSoon: false,
        description: null,
      },
      {
        id: 510,
        title: 'Mobile Development',
        href: `/services/mobile-development` as INextLink,
        iconName: 'sprite/ux-ui-design',
        isComingSoon: false,
        description: null,
      },
      {
        id: 515,
        title: 'UX / UI Design',
        href: `/services/design` as INextLink,
        iconName: 'sprite/mobile-development',
        isComingSoon: false,
        description: null,
      },
      {
        id: 520,
        title: 'Quality Assurance',
        href: `/services/qa` as INextLink,
        iconName: 'sprite/qality-testing',
        isComingSoon: false,
        description: null,
      },
    ],
  },
  {
    id: 10,
    title: 'Industries',
    titleAlternative: 'Industries',
    href: '/industries' as INextLink,
    hasFoodtech: true,
    subnav,
  },
  {
    id: 15,
    title: 'Portfolio',
    titleAlternative: 'Our portfolio',
    href: '/portfolio' as INextLink,
    subnav: [],
  },
  { id: 25, title: 'Blog', titleAlternative: 'Our blog', href: '/blog' as INextLink, subnav: [] },
  {
    id: 20,
    title: 'Contacts',
    titleAlternative: 'Contacts',
    href: '/contacts' as INextLink,
    subnav: [],
  },
]
