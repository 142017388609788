import _chunk from 'lodash.chunk'
import { ICase } from '../model'

export const filterCasesByCategory = (category: string, works: ICase[]) => {
  let result = []

  if (category === 'all') {
    result = works
  } else {
    result = works.filter((work) => work.category?.slug === category)
  }

  return result
}

export const filterCasesByCategoryWithPagination = (
  category: string,
  works: ICase[],
  page: number,
  perPage: number,
) => {
  const portfolio = filterCasesByCategory(category, works)

  return {
    data: _chunk(portfolio, perPage)[page - 1] ?? [],
    meta: {
      total: portfolio.length,
      lastPage: Math.ceil(portfolio.length / perPage),
    },
  }
}

export const filterCasesBySlugs = (slugs: string[], works: ICase[]) => {
  const filteredCases = works.filter((i) => slugs.includes(i.slug ?? ''))

  return filteredCases.sort((a, b) => slugs.indexOf(a.slug ?? '') - slugs.indexOf(b.slug ?? ''))
}
