import { IButtonProps } from '@/app/src/shared/ui/Button/lib'
import { Icon } from '@/app/src/shared/ui/Icon'

export const IconWithText = ({
  hasIconRight,
  hasIconLeft,
  iconNameRight = 'arrows/chevron-right',
  iconNameLeft = 'arrows/chevron-left',
  children,
}: IButtonProps) => {
  return (
    <>
      {hasIconLeft && <Icon className={iconNameLeft} name={iconNameLeft} width="30" height="30" />}

      <span>{children}</span>

      {hasIconRight && (
        <Icon className={iconNameRight} name={iconNameRight} width="30" height="30" />
      )}
    </>
  )
}
