'use client'

import React from 'react'

import { IButtonProps, buttonLib } from '@/app/src/shared/ui/Button/lib'
import { Tag as ButtonTag } from '@/app/src/shared/ui/Button/Tag'

import './Button.scss'

const Button = (props: IButtonProps) => {
  const {
    children,
    size,
    href,
    disabled,
    hasRightNeighbour,
    hasLeftNeighbour,
    hoverWithInRightNeighbour,
    external,
    onClick,
    type,
    tag,
  } = props

  const classesNeighborWrapper = buttonLib.getButtonNeighbourWrapperClasses({
    hasLeftNeighbour,
    hasRightNeighbour,
    hoverWithInRightNeighbour,
  })

  const hasNeighbour = hasLeftNeighbour || hasRightNeighbour

  const neighbourProps = {
    size,
    variant: 'main',
    hoverVariant: 'purple-light',
    disabled,
    type,
    href,
    external,
    tag,
    squared: true,
    onClick,
  } as const

  const renderMainButton = <ButtonTag {...props}>{children}</ButtonTag>

  return (
    <>
      {hasNeighbour ? (
        <div className={classesNeighborWrapper}>
          {hasLeftNeighbour && (
            <Button
              className="button-neighbour-wrapper__left"
              hasIconLeft
              iconNameLeft="arrows/arrow-left"
              {...neighbourProps}
            >
              {children}
            </Button>
          )}

          {renderMainButton}

          {hasRightNeighbour && (
            <Button
              className="button-neighbour-wrapper__right"
              hasIconRight
              iconNameRight="arrows/arrow-right"
              {...neighbourProps}
            >
              {children}
            </Button>
          )}
        </div>
      ) : (
        <>{renderMainButton}</>
      )}
    </>
  )
}

export default Button
