import {
  filterCasesByCategory,
  filterCasesByCategoryWithPagination,
  filterCasesBySlugs,
} from './filters'
import { categories, cases } from './data'

export const getCategoriesForPortfolio = () => {
  return categories.filter((category) => category.showInPortfolio)
}

export const getCategoriesForIndustries = () => {
  return categories.filter((category) => category.showInIndustries)
}

export const getCategoryBySlug = (slug: string) => {
  return categories.find((category) => category.slug === slug)
}

export const getCasesForPortfolio = () => {
  return cases.filter((work) => work?.showInPortfolio)
}

export const getCasesForPortfolioByCategory = (category: string) => {
  return filterCasesByCategory(
    category,
    cases.filter((work) => work?.showInPortfolio),
  )
}

export const getCasesForIndustryByCategory = (category: string) => {
  return filterCasesByCategory(category, cases)
}

export const getCasesForPortfolioByCategoryWithPagination = (
  category: string,
  page: number,
  perPage: number,
) => {
  return filterCasesByCategoryWithPagination(
    category,
    cases.filter((work) => work?.showInPortfolio),
    page,
    perPage,
  )
}

export const getCasesBySlugs = (slugs: string[]) => filterCasesBySlugs(slugs, cases)
export const getCaseBySlug = (slug: string) => cases.find((work) => work.slug === slug)
